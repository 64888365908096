@import '@fontsource/rubik/latin-400';
@import 'bulma/css/bulma';
@import '@fortawesome/fontawesome-free/css/fontawesome';
@import '@fortawesome/fontawesome-free/css/solid';

@import 'modules/navbar';
@import 'modules/dark';
@import 'modules/cards';
@import 'modules/dropdown';

body, ::placeholder {
  font-family: 'Rubik', sans-serif !important;
}

.section, html {
  background-color: rgb(238, 238, 238);
  min-height: 33vh;
}

.refresh {
  cursor: pointer;
  font-size: smaller;
}

/* fix search and dropdown arrow position on ie11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
  .input {
    margin: auto;
  }

  .dropdown-trigger .icon {
    margin-right: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }
}
